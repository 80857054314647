.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 1rem;
}

.image {
  position: relative;
  left: 60px;
  flex: 0 1 583px;
  max-height: 583px;

  @media (max-width: 768px) {
    left: 0;
    flex: 0;
  }
}
