@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  width: 100%;
  height: 120px;
  display: flex;
  flex-flow: column;
  gap: 14px;
  margin-bottom: 20px;
  align-items: center;

  @include xs-block() {
    height: 85px;
    gap: 6px;
    margin-bottom: 12px;
  }

  .row {
    display: flex;
    flex-flow: row;
    gap: 14px;

    @include xs-block() {
      gap: 6px;
    }
  }

  .digit {
    width: 84.5px;

    @include xs-block() {
      width: 67.5px;
      height: 29px !important;
    }
  }

  .increase {
    width: 62px;
    padding-left: 3px;

    @include xs-block() {
      width: 38px;
    }

  }

  .element {
    padding-top: 2px;
    height: 53px;
    border-radius: 16px;
    border: 1px solid #2A2A2A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.05px;
    letter-spacing: 0.1em;
    cursor: pointer;
    text-align: center;

    @include xs-block() {
      border-radius: 8px;
      height: 38px;
      padding: 6px 12px 6px 14px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.15px;
      text-align: center;
    }
  }

  input {
    width: 228px !important;
    background: transparent;
    color: white;
    cursor: unset;

    @include xs-block() {
      width: 200px !important;
    }

  }

}