@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.slotsBlock {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  height: 100%;

  @include xs-block() {
    display: flex;
    flex-flow: column;
    gap: 16px;
    margin-bottom: 32px;

    a {
      width: 100%;
    }
  }
}

.title {
  width: 100%;
  margin-top: 42px;
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  line-height: 39.2px;
  margin-bottom: 19px;

  @include xs-block() {
    margin-bottom: 12px;
    margin-top: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      @include gradientBorderBottom();
      @include gradientText();
      position: relative;
      z-index: 1;
      font-size: 13px;
      font-weight: 600;
      line-height: 15.93px;
      letter-spacing: 0.002em;
    }
  }
}
