@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  min-width: 150px;
  height: 100%;
}

.input {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid $gray;
  overflow: hidden;
  background: $black;
  min-height: 50px;

  @include sm-block() {
    background: $BlackBackground;
  }

  @include xs-block() {
    min-height: 38px;
  }

  > input {
    height: 100%;
    padding: 0 15px;
    border: none;
    margin: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    overflow: hidden;
    background: $black;
    color: #fff;
    outline: none;

    @include sm-block() {
      background: $BlackBackground;
    }

    &::placeholder {
      color: $grayLight;
    }

    &::-ms-input-placeholder {
      color: $grayLight;
    }
  }

  > svg {
    position: absolute;
    right: 20px;
    cursor: text;

    @include xs-block() {
      right: 11px;
      width: 14px;
    }
  }
}

.clearWrapper {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
}

.clear {
  border: none;
  background: inherit;
  outline: none;
}

.footer {
  display: flex;
  height: 32px;
  align-items: end;
  width: 100%;
  border-top: 1px solid #443e3f;
  justify-content: space-between;

  .button {
    color: #fff;
    border: none;
    background: inherit;
    outline: none;
    font-weight: 600;

    &:hover {
      background: $GoldGradientHover;
      color: $black;
      border-radius: 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.modal {
  background: #111112 !important;
  border: 1px solid #1a1a1a !important;
  width: 288px;
  height: 270px;
  padding: 10px 14px !important;
}

.modalBackground {
  background: unset !important;
}
