@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  margin-top: 14px;

  @include xs-block() {
    margin-top: 0;
  }
}

