@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.fileDropWrapper {
  width: 100%;
  display: flex;
  flex-flow: row;

  img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #A4A4A4;
    min-width: 52px;
    margin-right: 7px;
  }
}


.imitation {
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  border: 1px solid $gray;
  overflow: hidden;
  background: $black;
  min-height: 50px;
  padding: 14px 45px 14px 20px;
  align-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  letter-spacing: 0.04px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #A4A4A4;
}

.input {
  display: none;
}

.icon {
  position: absolute;
  right: 20px;
  top: 30%;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
  }
}