@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.pinWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px;

  @include sm-block() {
    margin-bottom: 0;
    margin-top: 16px;
  }

  @include xs-block() {
    margin-top: 4px;
    gap: 20px;
  }
}

.pin {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;

  @include sm-block() {
    display: none;
  }
}

.pinInputWrapper {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  gap: 15px;
  flex-direction: row !important;

  .pinInput {
    &.success {
      border-bottom: 1px solid $red !important;
    }

    &.error {
      border-bottom: 1px solid $GreenGradientDefault !important;
    }
  }
}

.inputs1 {
  input {
    &:nth-child(1) {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 1) !important;

      @include xs-block() {
        border: 1px solid rgba($color: #fff, $alpha: 1) !important;
      }
    }
  }
}

.inputs2 {
  input {
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 1) !important;
      @include xs-block() {
        border: 1px solid rgba($color: #fff, $alpha: 1) !important;
      }
    }
  }
}

.inputs3 {
  input {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 1) !important;
      @include xs-block() {
        border: 1px solid rgba($color: #fff, $alpha: 1) !important;
      }
    }
  }
}

.inputs4 {
  input {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 1) !important;
    @include xs-block() {
      border: 1px solid rgba($color: #fff, $alpha: 1) !important;
    }
  }
}

.pinInput {
  width: 100px !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2) !important;
  color: #fff !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  margin-right: 0 !important;
  box-shadow: none !important;
  background: inherit !important;
  position: relative;

  @include xs-block() {
    font-size: 14px !important;
  }

  &:valid {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2) !important;

    @include sm-block() {
      border: 1px solid $gray !important;
    }
  }

  &:focus {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 1) !important;

    @include xs-block() {
      border: 1px solid rgba($color: #fff, $alpha: 1) !important;
    }
  }

  @include sm-block() {
    width: 55px !important;
    height: 38px !important;
    background: $BlackBackground !important;
    border: 1px solid $gray !important;
    border-radius: 8px !important;
    position: relative;

    &::placeholder {
      position: absolute;
      font-size: 32px !important;
      left: 50%;
      bottom: -5px;
      width: 26px;
      color: #a4a4a4;
      margin: 0 auto;
      transform: translateX(-50%);
    }
  }
}
