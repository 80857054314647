@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapperHeader {
  position: fixed;
  bottom: 0;
  z-index: 60;
  background: $BlackBackground;
  padding: 6px;
  width: 100%;
  border-top: 1px solid $red;
}

.wrapper {
  display: flex;
  gap: 44px;
  justify-content: center;
  align-items: center;

  @media (max-width: 567px) {
    gap: 12px;
  }

  @include xs-block() {
    justify-content: center;
  }
}

.navigationBlock {
  transition: all 0.3s ease 0s;
  transform: scale(0);

  &.active {
    transform: scale(1);
  }

  &.navBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    background: rgba(35, 31, 32, 0.85);
    border: 1px solid #443e3f;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    border-radius: 30px 30px 0 0;
    padding: 15px 0;
    gap: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 1.5px 0 0 0;
      background: $gold_button;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  }
}

.navContainer {
  transform: translateX(0) scale(1);
  transition: all 0.3s ease 0s;

  @media (max-height: 340px) {
    height: 70vh;
    overflow: auto;
    padding-top: 2rem;
  }

  &.hiddenNavBar {
    transform: translateX(-100%) scale(1);
  }
}

.langBlock {
  transform: translateX(100%) scale(0);
  height: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;

  &.showLang {
    opacity: 1;
    height: auto;
    transform: translateX(0) scale(1);
  }
}

.flex-container {
  align-content: center;
  border-bottom: 1px solid $GrayDark;
  padding: 20px 0;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  text-transform: none;
  line-height: 22px;
  position: relative;
  width: 100% !important;
  align-items: flex-start;
  background-color: inherit;

  &.link {
    height: 40px;
    display: flex;
    align-items: center;

    a {
      height: 20px;
      display: inline;
      padding-right: 20px;
    }
  }

  &.active {
    background: $GoldGradientDefault;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-mask: none;
    background-clip: text;
    color: transparent;
  }

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: radial-gradient(50% 50% at 50% 50%, #443e3f 69.79%, rgba(68, 62, 63, 0) 100%);
  }

  @include xs-block() {
    font-size: 14px;
    padding: 0;
    justify-content: flex-start;
  }

  span,
  img {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    margin-right: 20px;

    @include xs-block() {
      width: 24px;
      height: 24px;
    }
  }
}
