.wrapper {
  margin: auto;
  gap: 40px;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.images {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-image: url('../../../../Assets/img/quiz/lang_bg.webp');

  @media (max-width: 768px) {
    background-image: url('../../../../Assets/img/quiz/lang_bg_mobi.webp');
  }
}
