@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
  margin-bottom: 29px;
  display: inline-block !important;
  z-index: 0;
  min-height: 52px;
  padding: 1px;

  @include xs-block() {
    margin-bottom: 21px;
    min-height: 40px;
  }

  .iconStart {
    padding-left: 40px;
  }
}

.focused {
  @include gradientBorder(12px);
}

.inputPassTextHidden {
  font-size: 20px !important;
  padding-top: 9px !important;

  @include xs-block() {
    font-size: 17px !important;
    padding-top: 7px !important;
  }
}

.input {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid $gray;
  overflow: hidden;
  background: $black;
  min-height: 50px;
  color: #fff;
  padding: 12px 45px 12px 15px;
  outline: none;
  z-index: 1;
  pointer-events: auto;
  font-size: 16px;
  font-weight: 500;

  @include sm-block() {
    background: $BlackBackground;
  }

  @include xs-block() {
    min-height: 38px;
    padding: 7px 15px;
    font-size: 14px;
  }

  &.inputError {
    min-height: 50px;
    border: 1px solid $GreenGradientDefault;

    @include xs-block() {
      min-height: 38px;
    }
  }

  &::placeholder {
    color: $grayLight;
    font-weight: 500;
    font-size: 16px !important;

    @include xs-block() {
      font-weight: 500;
      font-size: 14px !important;
    }
  }

  &::-ms-input-placeholder {
    color: $grayLight;
  }
}

.disabled {
  border: none;
  color: $grayLightVery;
}

.error {
  span {
    position: absolute;
    bottom: -20px;
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: $GreenGradientDefault;
    left: 0;

    @include xs-block() {
      bottom: -17px;
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  height: fit-content;

  @include xs-block() {
    right: 11px;
  }

  &.closeIcon {
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;

      @include xs-block() {
        width: 14px;
      }

      > * {
        stroke: #fff;
      }
    }
  }

  &.editIcon {
    cursor: default;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #fff;
      }
    }
  }

  &.disabled {
    cursor: default;

    svg {
      path {
        fill: $gray;
      }
    }
  }

  &.start {
    right: auto;
    left: 0;
  }
}

.glassWrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;

  @include xs-block() {
    right: 11px;
  }

  svg {
    margin: 0 !important;
    height: auto !important;
    width: 20px;
    padding: 0 !important;

    @include xs-block() {
      width: 14px;
    }
  }

  svg > * {
    stroke: #fff;
  }
}

input:-internal-autofill-selected {
  box-shadow: 0 0 0 1000px $black inset;
  background-color: $black !important;
  -webkit-text-fill-color: #fff !important;
}
