@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  border-radius: 14px;
  background: $black;
  padding: 18px 16px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;

  @include md-block() {
    background: #1d1d1d;
  }

  @include sm-block() {
    padding: 12px 16px;
    font-size: 18px;
  }
}

.title {
  font-size: 18px;
  color: #717171;

  @include sm-block() {
    font-size: 14px;
    font-weight: 600;
  }
}
