@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 100px;
  background: $GreyDark_2;
  padding: 0 26px;
  grid-column: 1 / 4;
  overflow-x: auto;
  overflow-y: hidden;

  @include md-block() {
    border-radius: 0;
    background: none;
    grid-column: 1 / 5;
    padding: 0;
  }

  .item {
    display: flex;
    position: relative;
    align-items: center;
    gap: 10px;
    color: #fff;
    opacity: 0.7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 13px 0;
    cursor: pointer;

    @include xs-block {
      gap: 1px;
      padding: 3px;
      font-size: 10px;
    }

    img {
      width: 23px;
      height: 23px;

      @include xs-block {
        width: 16px;
        height: 16px;
      }
    }

    @include md-block {
      width: 100%;
      border-radius: 10px;
      flex-direction: column;
      background: $GreyDark_2;
      min-width: 83px;
      flex: 1 0 83px;
      height: 65px;
      justify-content: center;
    }

    @include xs-block {
      height: 40px;
    }

    &.active {
      opacity: 1;
      cursor: default;

      &::after {
        content: '';
        height: 3px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px 4px 0px 0px;
        position: absolute;
        background: $RedMain;
        bottom: -1px;
      }

      @include md-block {
        background: $RedMain;

        // border-bottom: 1px solid white
        &::after {
          content: '';
          width: 80%;
          background: $gold_line;
        }
      }
    }
  }
}

.searchWrapper {
  @include md-block() {
    display: none;
  }
}

.menuWrapper {
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 15px;

  .search {
    max-width: 100%;
  }
}

.search {
  margin: 0;
  border-radius: 100px;
  border: 1px solid $GrayDark;
  background: #fff;
}

.text {
  color: $GreyDark_3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: none;

  p {
    padding: 0;
    margin: 0 !important;

    @include xs-block() {
      font-size: 10px !important;
      line-height: 13px;
      padding: 0;
    }
  }
}
