@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  flex-flow: column;
  gap: 6px;
  justify-content: center;
  margin-bottom: 8px;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.98px;
    letter-spacing: 0.02px;
    color: #D80027;
  }

  .input {
    display: none;
  }

  .letter {
    font-weight: 600;
    font-size: 42px;
    line-height: 51.45px;
    text-align: center;
    align-content: center;
    height: 100%;
    position: relative;
    z-index: 1;

    @include gradientText(linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%));
  }

  .imageWrapper {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    position: relative;
    z-index: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include gradientBorder(40px);

    .image {
      width: 64.61px;
      height: 64.61px;
      border-radius: 32px;
      position: relative;
      z-index: 0;

      svg {
        position: absolute;
        z-index: 0;
        top: 11px;
        left: -8px;
      }

      img {
        height: 63px;
        width: 63px;
        border-radius: 32px;
        z-index: 1;
        position: relative;
        object-fit: cover;
      }

      @include gradientBorder(32px);
    }
  }
}