.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;
  justify-content: center;
  position: relative;
  left: -80px;

  @media (max-width: 768px) {
    flex-direction: column;
    left: 0;
    height: auto;
    gap: 50px;
  }
}

.image {
  position: relative;
  left: 60px;
  flex: 0 0 50%;

  @media (max-width: 768px) {
    left: 0;
    flex: auto;
  }
}
