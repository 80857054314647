@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.bets {
  display: flex;
  flex-flow: column;
  gap: 20px;
  height: calc(100vh - 664px);
  overflow-y: auto;
  padding-bottom: 20px;

  @include scrollbars(0, white);

  @include xs-block() {
    height: calc(100vh - 530px);
    padding-bottom: 0;
    gap: 12px;
  }
}

