@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.bets {
  display: flex;
  flex-flow: column;
  gap: 32px;
  height: calc(100vh - 412px);
  overflow-y: auto;
  padding-bottom: 20px;

  @include scrollbars(0, white);

  @include xs-block() {
    height: calc(100vh - 314px);
    gap: 12px;
    padding-bottom: 0;
  }

  .element {
    height: 360px;

    @include xs-block() {
      height: 247px;
      border-bottom: 1px solid #2A2A2A !important;

      &:last-of-type {
        border-bottom: 0 !important;
      }
    }
  }
}

