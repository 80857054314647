@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapperMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid $red;
  background: $BlackBackground;
  width: 100%;
  position: fixed;
  z-index: 60;
  top: 0;
  height: 54px;
  padding: 10px 15px;

  &.withCoverWrapper {
    z-index: 10002;
  }

  @include xs-block() {
    padding-bottom: 10px;
  }

  :global {
    .header-logo {
      width: 100%;
      max-width: 106px;
      height: 34px;

      @include xs-block() {
        max-width: 106px;
        height: 34px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 1000;
  background-color: rgba($black, 0.8);
}

.logoMenu {
  display: flex;
  gap: 14px;
  align-items: center;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  position: relative;
  width: 26px;

  svg {
    width: 100%;
    height: 100%;
  }

  @include xs-block() {
    height: 10px;
    width: 16px;
  }

  @media (any-hover: hover) {
    &:hover {
      span {
        background: $GoldGradientHover;
      }
    }
  }
}

.avatarMobileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @include xs-block() {
    gap: 5px;
  }

  .wallet {
    display: flex;
    height: 34px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid $red;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    gap: 4px;
    cursor: pointer;

    @media (any-hover: hover) {
      &:hover {
        .plus {
          background: $RedGradientHover;
        }
      }
    }

    .plus {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background: $RedGradientDefault;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    .count {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
}

.avatarMobile {
  height: 50px;
  width: 50px;

  @include xs-block() {
    height: 40px;
    width: 40px;
  }

  svg {
    transform: scale(1.4);

    @include xs-block() {
      transform: scale(1);
    }
  }
}

.profile {
  position: relative;
  border-radius: 100%;
  z-index: 2;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.withCover {
    z-index: 1002;
  }

  &.ready {
    animation: pulse 1.5s infinite ease-in-out;

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.3);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  img {
    border-radius: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 2px;
    background: $GoldGradientDefault;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    z-index: 2;
  }

  svg {
    width: 50px;
    height: 50px;

    @include xs-block() {
      height: 40px;
    }
  }
}

.editAvatar {
  position: relative;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 100%;
  z-index: 2;

  img {
    object-fit: cover;
    width: 32px;
    height: 32px;
  }

  svg {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    z-index: 6;
    bottom: 0;
    animation: pulse 1.5s infinite ease-in-out;

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.2);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 2px;
    background: $GoldGradientHover;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}

.menuOpen {
  position: fixed;
  top: 54px;
  width: 100%;
  left: 0;
  height: calc(100% - 54px);
  background: $BlackBackground;
  z-index: 55;
  overflow: auto;
  padding: 0 16px;
}

.menuOpenTooltips {
  z-index: 61;
}

.cover {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: #0B0B0CE5;
  opacity: 0.8;
  z-index: 1;
  left: 0;
}

.menuListElement {
  position: relative;
}

.menuList {
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #c6c6c6;

  a {
    padding: 6px 0;
  }
}

.social {
  height: 68px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.loginProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  border-bottom: 1px solid $red;
  gap: 8px;

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    color: #c6c6c6;
    margin-bottom: 4px;
  }
}
