@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.checkbox {
  margin-bottom: 30px;
  text-transform: none;
  width: 230px;
  margin-left: 3px;

  @include xs-block() {
    margin-bottom: 6px;
  }

  span {
    color: #fff;
  }
}

.checkbox__check {
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.disabled {
    &:after {
      background: none !important;
    }

    &:before {
      content: none;
    }
  }

  &::after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: $RedGradientDefault !important;
    position: absolute;
    top: 0;
    left: -3.5px;
    transform: translate(0, -50%);
  }

  &:before {
    content: '';
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 3px;
    width: 7px;
    height: 3px;
    border-radius: 1px;
    border-left: 1.5px solid white;
    border-bottom: 1.5px solid white;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    top: 50%;
    transform: rotate(-45deg) translateY(-100%);
    z-index: 2;
  }
}

.actionStep {
  display: flex;
  color: #b1b1b1;
  font-size: 14px;
  flex-direction: row !important;
  margin-bottom: 6px;
  font-weight: 500;
  gap: 5px;
  text-transform: none;
  flex-wrap: wrap;

  @include xs-block() {
    max-width: 320px;
    font-size: 13px;
    justify-content: center;
  }

  span {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
  }
}
