@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.inSlider {
  width: 345px;
}

.cardSlotItem {
  width: 100%;
  min-width: 280px;
  height: 253px;
  display: flex;
  gap: 0;
  flex-flow: column;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #2A2A2A;
  background: #060606;

  @include xs-block() {
    height: 182px;
    margin: 0;
  }

  img {
    height: 204px;
    width: 100%;
    object-fit: cover;

    @include xs-block() {
      height: 141px;
    }
  }

  .title {
    background: #060606;
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;

    @include xs-block() {
      height: 41px;
    }
  }
}
