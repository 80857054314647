@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.swiper {
  padding-bottom: 150px;
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .disabled {
    border: 1px solid #565656;
    border-radius: 50px;

    svg {
      path {
        fill: #565656;
      }
    }

    &::before {
      display: none;
    }
  }

  .buttons {
    position: absolute;
    bottom: 94px;
    display: flex;
    flex-flow: row;
    gap: 20px;

    .button {
      position: relative;
      height: 34px;
      width: 34px;
      @include gradientBorder(50px);
      @include gradientText();
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:first-of-type {
        padding-right: 2px;
      }
      &:last-of-type {
        padding-left: 2px;
      }
    }
  }
}
