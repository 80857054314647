@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 73px;
  transition: all 0.3s ease 0s;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  outline: none;
  touch-action: manipulation;

  @include xs-block() {
    min-width: 40px;
  }

  &.active {
    cursor: default;

    .title {
      background: $GoldGradientText;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .image {
      svg path {
        all: revert-layer;
      }
    }
  }
}

.image {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: $grayLight;
    }
  }

  &.mainImage {
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translateY(-62px);
    background: $GreyDark_3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      padding: 1.5px;
      background: $gold_button;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    @include sm-block() {
      width: 90px;
      height: 90px;

      img {
        width: 90px;
        height: 90px;
      }
    }

    @include xs-block() {
      width: 50px;
      height: 50px;
      transform: translateY(-35px);

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.title {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600;
  color: $grayLight;
  line-height: 14.7px;
}
