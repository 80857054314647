@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  &.won {
    color: $red;
  }

  &.win_half_1,
  &.win_half_2,
  &.lose_half_1,
  &.lose_half_2 {
    color: #7792b6;
  }

  &.loss {
    color: $GreenGradientDefault;
  }

  &.progress {
    color: #ffb30d;
  }

  &.refund {
    color: #6bab2196;
  }

  &.refund_error {
    color: #7792b6;
  }
}
