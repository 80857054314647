@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  z-index: 2;
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin: auto 0;

  h2 {
    font-weight: 600!important;
    font-size: 50px!important;
    line-height: 61.25px!important;
    letter-spacing: 0.03px!important;
    text-align: center;

    @include xs-block() {
      font-weight: 700!important;
      font-size: 26px!important;
      line-height: 32.19px!important;
      text-align: center;
    }
  }

  :global {
    .center {
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    gap: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.buttonCollect {
  max-width: 345px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 284px;
    margin: 0 auto;
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;

  @include sm-block() {
    gap: 28px;
  }

  > div {
    width: 345px;

    @media (max-width: 768px) {
      width: 100%;
      flex: 1;
    }
  }
}
