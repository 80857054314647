@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  flex-flow: column;
  gap: 20px;
  overflow-y: auto;
  height: calc(100vh - 261px);

  @include scrollbars(0, white);

  @include xs-block() {
    height: calc(100vh - 180px);
    padding-top: 12px;
  }
}