@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  text-align: center;
  width: 100%;

  @include sm-block() {
    text-align: left;
  }

  &.two {
    .title {
      max-width: 350px;
      margin: 20px auto;

      @include sm-block() {
        margin-bottom: 16px;

      }
    }

    .text {
      max-width: 283px;
      margin-bottom: 30px;

      @include sm-block() {
        margin-bottom: 16px;
      }
    }
  }
}

.mobileWrapper {
  width: 100%;
  height: 100%;
  background-image: url('../../../../shared/assets/quiz/img/background_demo_user_mobile.webp');
  background-size: cover;

  .buttonNext {
    border-radius: 8px!important;
  }

  .cover {
    padding: 20px 16px 40px 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    background: #111112F0;
    opacity: 0.94;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .info {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px 9px 12px;
    background: #D80027;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.02px;
    vertical-align: middle;
    border-radius: 8px;
    margin-bottom: 12px;
    gap: 10px;
  }

  .logo {
    width: 132px;
    height: 42px;
    margin-bottom: 34px;
  }

  .title {
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
  }

  .text {
    max-width: 220px;
    margin-bottom: 26px;
    margin-left: 0;
  }

  .label {
    margin-bottom: 4px;
  }

  .textRow {
    height: 24px !important;
  }

  .button {
    width: 100%;
    margin-top: auto;
  }
}

.textBlock {
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 460px;

  @include xs-block() {
    gap: 14px;
    width: 100%;
  }

  .element {
    display: flex;
    flex-flow: column;
    width: 100%;

    .label {
      font-weight: 400;
      font-size: 18px;
      line-height: 21.6px;
      color: #A4A4A4;
      text-align: left;

      @include xs-block() {
        font-weight: 400;
        font-size: 13px;
        line-height: 15.6px;
      }
    }

    .textRow {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      line-height: 24.5px;
      letter-spacing: 0.02px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;

      @include xs-block() {
        font-weight: 600;
        font-size: 15px;
        line-height: 18.38px;
        letter-spacing: 0.02px;
      }

      svg {
        width: 22px;
        height: 22px;
        cursor: pointer;

        path {
          fill: #FFFFFF;
        }

        @include xs-block() {
          width: 15px;
          height: 15px;
          path {
            fill: #A3A2A4;
          }
        }
      }
    }
  }
}

.backgroundModal {
  background: #0B0B0CCC;

}

.title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;

  @include sm-block() {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.81px;
  }

  span {
    @include gradientText($GoldGradientDefault);
  }
}

.text {
  font-size: 18px;
  font-weight: 400;
  max-width: 370px;
  margin: 0 auto 40px;

  @include sm-block() {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin: 0 auto 26px;
  }
}

.button {
  margin-top: 24px;
}
