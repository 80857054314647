@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  margin-bottom: 30px;

  @include xs-block() {
    margin-bottom: 11px;
  }

  :global {
    .react-select__value-container {
      opacity: 1;
      font-size: 14px;
      padding: 0 5px 0 15px;
      overflow: visible;
    }

    .react-select__menu-list {
      background: $black;
      max-height: 209px;
      z-index: 100;
      overflow: auto;

      @include scrollbars(4px, $red, $gray);

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #bebebe69;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ec620f;
      }

      placeholder {
        position: absolute;
        top: 20%;
        transition:
          top 0.2s,
          font-size 0.2s;
        font-size: 13px;
        opacity: 0.5;
      }
    }

    .react-select__control {
      background: $black;
      border: 1px solid $gray;
      min-height: 52px;
      border-radius: 12px;
      color: #fff;

      &:hover {
        border-color: $red !important;
      }

      box-shadow: none !important;
      cursor: pointer;
    }

    .react-select__control--menu-is-open {
      border: 1px solid $red;
    }

    .react-select__menu {
      width: 100%;
      border-radius: 12px;
      background: $black;
      border: 1px solid $red;
      overflow: hidden;
      box-shadow: 1px 9px 12px 0 rgba(0, 0, 0, 0.25);
      max-height: 190px;
      z-index: 100;
      margin-top: 0;
    }

    .react-select__menu {
      top: calc(100% + 16px);
    }

    .react-select__single-value {
      text-align: left;
      color: #ffff;
    }

    .react-select__indicator {
      padding-left: 0;
      padding-right: 20px;

      @include xs-block() {
        padding: 0 8px;

        svg {
          width: 14px;
        }
      }
    }
  }

  &.simple {
    :global {
      .react-select__menu {
        width: 100%;
      }
    }
  }

  &.code {
    .option {
      padding: 0 10px;
    }
  }

  &.isHigh {
    :global {
      .react-select__control {
        height: 45px;

        @include xs-block() {
          height: 32px;
          min-height: 32px;
        }
      }
    }
  }

  &.disabled {
    :global {
      .react-select__control {
        border: none;
        color: $grayLightVery;
      }

      .react-select__indicator {
        opacity: 0.5;
      }
    }
  }
}

.option {
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  background: $black;
  color: #fff;
  line-height: 150%;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;
  text-align: left;

  > span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
  }

  @media (any-hover: hover) {
    &:hover {
      color: $RedMain;
    }
  }

  &.active {
    cursor: default;
    color: $RedMain;
    @media (any-hover: hover) {
      &:hover {
        color: $GreyDark_3;
      }
    }

    .check {
      border: 1px solid $RedMain;

      &::after {
        content: '';
        width: 9px;
        height: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background: red;
      }
    }
  }

  &.inherit {
    background: inherit;
    color: #ccc;
    padding: 0 5px;
  }

  &.simple {
    width: 100%;
  }
}

.check {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid $GrayDark;
  border-radius: 100%;
  position: relative;
  flex: 0 0 14px;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
