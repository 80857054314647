@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.right {
    justify-content: flex-end;
    font-weight: 400;
    color: $grayLight;
  }
}

.moreInfo {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.3s ease 0s;

  > div {
    overflow: hidden;
  }

  &.open {
    grid-template-rows: 1fr;
  }
}

.moreWrapper {
  border-top: 1px solid $gray;
  z-index: 60;

  overflow: auto;

  .row {
    padding: 12px 0;
    border-bottom: 1px solid $gray;
    gap: 10px;

    &:last-child {
      border: none;
    }
  }
}

.copyWrap {
  display: flex;
  gap: 10px;
  align-items: center;

  svg path {
    fill: $grayLight;
  }
}
