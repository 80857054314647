@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapperWithGif {
  width: 408px !important;
}

.wrapperProfile {
  left: -179px !important;
  top: 64px!important;

  &:before {
    left: 378px!important;
  }
}

.wrapperShopMobile {
  top: -150px!important;

  &:before {
    top: 134px!important;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-top: 0 !important;
    border-left: 0 !important;
  }
}


.wrapper {
  width: 278px;
  padding: 8px 14px;
  background: #111112;
  border: 1px solid #333333;
  position: absolute;
  top: 51px;
  left: 0;
  z-index: 10006;
  border-radius: 8px;
  cursor: default;

  @include xs-block() {
    top: 55px;
    left: 5px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -7px;
    left: 26px;
    width: 13px;
    height: 13px;
    transform: translateX(-50%) rotate(45deg);
    background: #111112;
    border-top: 1px solid #333333;
    border-left: 1px solid #333333;
    z-index: -1;
   }

  .image {
    width: 380px;
    height: 203px;
    border-radius: 7px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-flow: column;
    gap: 14px;

    .text {
      width: 250px;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-transform: none;
      color: #FFFFFF;
    }

    .textWithGif {
      width: unset!important;
    }

    .buttons {
      display: flex;
      flex-flow: row;
      gap: 14px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.7px;
      letter-spacing: 0.02px;
      justify-content: right;
      align-items: center;

      .button {
        cursor: pointer;
        background: $GoldGradientDefault;
        color: #030304;
        border-radius: 8px;
        height: 31px;
        width: 71px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        padding-top: 1px;
      }

      .next {
        background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%) !important;
        color: #FFFFFF !important;
      }
    }
  }
}