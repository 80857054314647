@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  background: #060606;

  @include xs-block() {
    padding: 20px 16px 0 16px;
    justify-content: start;
  }

  .content {
    border-radius: 16px;
    border: 1px solid #2A2A2A;
    display: flex;
    flex-flow: column;
    gap: 10px;
    padding: 20px;

    @include xs-block() {
      padding: 14px;
      border-radius: 12px;
    }

    & > :first-child {
      border-bottom: 1px solid #2A2A2A;
      padding-bottom: 14px;

      @include xs-block() {
        padding-bottom: 12px;
      }
    }

    .contentBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .textBlock {
        height: 100%;
        display: flex;
        flex-flow: column;
        gap: 4px;
        width: 172px;
        font-size: 16px;
        letter-spacing: 0.02em;
        text-align: left;

        @include xs-block() {
          width: 174px;
        }

        .label {
          line-height: 19.6px;
          font-weight: 600;
        }

        .text {
          font-weight: 400;
          line-height: 19.2px;
          color: #A4A4A4;
        }
      }

      .inputBlock {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        width: 166px;

        @include xs-block() {
          width: 86px;
        }

        input {
          height: 29px;
          background: #060606;
          color: white;
          padding: 6px 22px;
          border-radius: 8px;
          border: 1px solid #2A2A2A;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.7px;
          text-align: center;
        }
      }
    }
  }

  .footer {
    border-top: 1px solid #2A2A2A;
    padding: 20px;
    display: flex;
    flex-flow: column;
    gap: 20px;

    @include xs-block() {
      gap: 16px;
      padding: 28px 16px 0 16px;
      border-top: 0;
    }

    .default {
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      line-height: 24.5px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      border-radius: 12px;
      border: 1px solid #A4A4A4;
      color: #A4A4A4;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 52px;

      @include xs-block() {
        border-radius: 8px;
        min-height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.15px;
        letter-spacing: 0.02em;
      }
    }
  }
}