@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  flex-flow: row;
  gap: 20px;
  width: 100%;
  position: relative;
  margin-top: 64px;

  @include sm-block() {
    margin-top: 0;
    flex-flow: column;
    gap: 10px;
  }
}

.label {
  position: absolute;
  width: 100%;
  bottom: -124px;
  font-size: 68px;
  font-weight: 700;
  line-height: 62px;
  text-align: center;

  span {
    text-transform: uppercase;
    @include gradientText()
  }

  @include sm-block() {
    position: relative;
    margin-top: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    bottom: 0;
    text-align: left;
  }
}

.image {
  img {
    object-fit: cover;
    width: 960px;

    @include sm-block() {
      min-width: calc(100% + 32px);
      height: 218px;
      margin: 0 -16px;
    }
  }
}

.textBlock {
  width: 467px;
  display: flex;
  flex-flow: column;
  gap: 40px;

  @include sm-block() {
    width: 100%;
    gap: 28px;
  }
}

.title {
  font-size: 54px;
  font-weight: 700;
  line-height: 62px;
  text-align: left;
  letter-spacing: 0.01px;

  @include sm-block() {
    font-size: 24px;
    line-height: 32px;
  }

  span {
    @include gradientText()
  }
}

.description {
  margin-top: -20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  max-width: 420px;

  @include sm-block() {
    font-size: 14px;
    line-height: 22px;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.link {
  background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%);
  width: 270.5px;
  height: 53px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.5px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  @include sm-block() {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.15px;
    letter-spacing: 0.02em;
  }
}

.modaltext {

  font-weight: 700;
  font-size: 40px;
  line-height: 50.4px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 20px;

  @include gradientText();

  @include xs-block() {
    font-weight: 700;
    font-size: 26px;
    line-height: 32.19px;
  }
}

.modalDescription {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.76px;
  text-align: center;
  width: 384px;
  margin: 0 auto;

  @include xs-block() {
    font-weight: 700;
    font-size: 14px;
    line-height: 17.33px;
    text-align: center;
    width: 216px;
  }
}

.modal {
  width: 540px !important;
  padding: 40px !important;
  z-index: 0;

  @include gradientBorder(12px);

  @include xs-block() {
    padding: 16px !important;
    width: unset !important;
  }
}