@import '@/app/styles/mixins.scss';
@import '@/app/styles/variables.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.8);
  z-index: 10000;
  background-size: cover!important;

  &.blur {
    background: rgba(44, 40, 41, 0.5);
    backdrop-filter: blur(5px);
    padding: 0;
  }

  &.absolute {
    position: absolute;
  }

  &.withHeader {
    top: 80px;
    z-index: 9998;

    @include xs-block() {
      top: 54px;
    }
  }

  .header {
    height: 80px;
    z-index: 10001;
    max-width: 1400px;

  }

  &.inherit {
    background: inherit;
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 15px;
  display: flex;
  z-index: 999;
}



.modal {
  margin: auto;
  padding: 36px 24px;
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  background: #ffffff;
  background: $black;
  border: 1px solid $gray;
  border-radius: 12px;

  &.zeroOffset {
    padding: 0;
  }

  &.tiny {
    padding: 25px 30px 15px 30px;
    max-width: 540px;
  }

  &.small {
    max-width: 500px;
    width: 100%;
    padding: 20px 28px;

    @include xs-block {
      padding: 13px 4px;
      max-width: 288px;
    }
  }

  &.simple {
    background: $black;
    border: 1px solid $GrayDark;

    .close {
      transform: translate(-100%, 100%);
    }
  }

  &.light {
    color: $GrayDark;
    background: #fff;
  }

  &.deposit {
    padding: 40px;

    @include sm-block {
      background: $black;
      padding: 16px 14px;

      .close {
        transform: translate(0, 0);
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 650px;
  }

  @include sm-block {
    max-width: none;
    padding: 25px 20px;
  }
}

.close {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1000;

  svg {
    fill: $grayWhite;

    path {
      fill: $grayWhite;
    }
  }

  @include sm-block {
    top: 12px;
    right: 12px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.title {
  padding-bottom: 32px;
  margin-right: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  border-bottom: 1px solid #e0e0e0;

  .modalLarge & {
    padding: 13px 0 33px;
    text-align: left;
    border-bottom: none;
  }

  @include sm-block {
    font-size: 24px;
    line-height: 28px;
    max-width: 85%;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: none;
  }
}

.title + .body {
  margin-top: 26px;

  @include sm-block {
    margin-top: 15px;
  }
}

.body {
  flex-grow: 1;
  position: relative;
}
