@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.right {
    justify-content: flex-end;
  }
}

.grayText {
  font-weight: 400;
  color: $grayLight;
}

.columnName {
  color: #cfcfd0;
  font-weight: 500;
}

.value {
  color: #fff;
  font-weight: 700;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.text {
  width: 90%;
  text-align: right;
}

.arrow {
  flex: 0 0 20px;
  transition: all 0.3s ease 0s;

  &.rotate {
    transform: rotate(90deg);
  }

  svg {
    fill: #888888;
  }
}

.button {
  background: #ff0a13 !important;
  height: 29px !important;
  font-size: 14px;
  font-weight: 500;
}

.moreInfo {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.3s ease 0s;

  > div {
    overflow: hidden;
  }

  &.open {
    grid-template-rows: 1fr;
  }
}

.moreWrapper {
  border-top: 1px solid $gray;
  z-index: 60;
  overflow: auto;

  .row {
    padding: 12px 0;
    border-bottom: 1px solid $gray;
    gap: 10px;

    &:last-child {
      border: none;
    }
  }
}
