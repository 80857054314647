@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapperDiv {
  padding: 10.5px !important;
}

.wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 6px 1.5%;
  transition: all 0.3s ease 0s;

  @include xlg-block() {
    padding: 6px 1%;
  }

  &::after {
    content: '';
    top: 0;
    position: absolute;
    right: 0;
    height: 100%;
    width: 1px;
    background: #222222;
  }

  &:nth-last-child(3)::after,
  &:nth-last-child(4)::after,
  &:last-child::after {
    content: none;
  }

  &.nonAuthorized {
    margin-left: auto;
  }

  &.profile {
    display: flex;
    gap: 10px;
    min-height: 54px;
    padding: 0 0 0 24px;
    z-index: 1000;
    border-radius: 12px;
    position: relative;
    min-width: 189px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: '';
      top: 12px;
      position: absolute;
      left: 0;
      height: 30px;
      width: 1px;
      background: #222222;
    }
  }
}

.wrapperActiveTooltipProfile {
  padding-right: 10px !important;
}

.wrapperActiveTooltip {
  position: relative;
  z-index: 10006 !important;
  background: #111112 !important;
  border-radius: 8px !important;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    right: -9px;
    background: #111112 !important;
  }
}

 .wrapperActiveTooltipLink {
  position: relative;
  z-index: 10006;
  background: #111112 !important;
  border-radius: 8px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 1.5%;

  &:after {
    right: -9px;
    background: #111112 !important;
  }
}

.nonAuthorized {
  margin-left: auto;
}

.linkActive {
  color: $red;
}

.subMenu {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.avatarBlockReady {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 100%;
  justify-content: center;
  background: $GoldGradientDefault;
  position: relative;
  animation: pulse 1.5s infinite ease-in-out;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }

  svg {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    z-index: 6;
    bottom: 0;
  }
}

.avatarBlock {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 100%;
  justify-content: center;
  background: $GoldGradientDefault;
  position: relative;

  svg {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    z-index: 6;
    bottom: 0;
  }
}

.image {
  display: flex;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}

.item {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-width: 92px;
}

.itemWrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 192px;
  word-break: break-all;
  overflow: hidden;
}

.balance {
  font-size: 14px;
  font-weight: 500;
}

.menuDropdown {
  transition: all 0.3s ease 0s;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: -25px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 14px 12px;
  background: $black;
  min-width: 100%;
  border-radius: 12px;
  border: 1px solid #2A2A2A;
  gap: 12px;
  transform: translate(0, 90%);

  .cover {
    z-index: 101;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.8);
    top: 0;
    left: 0;
    border-radius: 12px;
  }

  &.profile {
    bottom: -14px;
  }

  &.active {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transform: translate(0, 100%);
  }
}

.unCovered {
  z-index: 102 !important;
  border-bottom: 1px solid $blackLight !important;
}

.element {
  cursor: pointer;
  padding-bottom: 12px;
  line-height: 19px;
  border: none;
  text-align: left;
  border-bottom: 1px solid $gray;
  white-space: nowrap;
  z-index: 99;
  position: relative;
  text-transform: capitalize;
  color: #fff;
  background: inherit;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.icon {
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
}

.routeBlock {
  position: fixed;
  inset: 0;
}
