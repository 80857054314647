@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 5px;

  svg,
  path {
    width: 10px;
    height: 10px;
    stroke: #d4d4d8;
  }
}

.bodyWrapper {
  top: 20px;
  position: absolute;
  left: 0;
  max-height: 220px;
  border: 1px solid $gray;
  display: none;
  border-radius: 12px;
  overflow: auto;
  padding: 20px 12px 10px 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.295);
  min-width: 55px;
  background: $black;
  z-index: 2;

  &.open {
    display: flex;
  }
}

.body {
  display: flex;
  background-color: $black;
  overflow: auto;
  gap: 10px;
  flex-direction: column;
  padding-right: 12px;

  @include scrollbars(1px, #fff, $gray);
}

.item {
  cursor: pointer;
  transition: all 0.3s ease 0s;
  padding: 2px 5px 2px 2px;
  font-size: 16px;
  color: #fff;
  height: 41px;
  flex: 0 0 41px;
  border-bottom: 1px solid $gray;
  font-weight: 600;

  &.selected {
    background: $GoldGradientDefault;
    color: $black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: $GoldGradientHover;
    color: $black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
