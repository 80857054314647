@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  height: 100%;
  position: relative;

  @include xs-block() {
    margin-top: -5px;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 39.2px;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 42px;
  }

  .iframe {
    height: 700px !important;

    @include md-block() {
      height: calc(100vh - 250px);
    }

    @include sm-block() {
      height: calc(100vh - 145px);
    }
  }

  .iframeFullScreen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
  }
}

.openFullScreen {
  position: absolute;
  right: 16px;
  top: 734px;
  cursor: pointer;
}

.closeFullScreen {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100001;
  cursor: pointer;
}

.slider {
  margin-bottom: -6px;
  margin-top: 60px;

  @include xs-block() {
    margin-left: 16px;
    margin-top: 26px;
  }
}

.swiper {
  width: unset !important;
  padding-top: 54px;

  @include xs-block() {
    padding-bottom: 50px;
  }

  .Slider_buttons {
    top: 0;
  }
}


.swiperButtons {
  top: 0;
  right: 0;
  bottom: unset !important;
  z-index: 100;

  @include xs-block() {
    display: none !important;
  }
}

.moreGames {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.4px;
  position: absolute;
  height: 34px;
  display: flex;
  align-items: center;

  @include xs-block() {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.5px;
    text-align: left;
  }
}