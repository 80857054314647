@import 'variables';

//sizes
$break_xlg: 1440px;
$break_lg: 1366px;
$break_md: 1180px;
$break_md1000: 1000px;
$break_sm: 768px;
$break_xs: 500px;

//Media queries
@mixin xlg-block() {
  @media screen and (max-width: $break_xlg) {
    @content;
  }
}

@mixin lg-block() {
  @media screen and (max-width: $break_lg) {
    @content;
  }
}

@mixin md-block() {
  @media screen and (max-width: $break_md) {
    @content;
  }
}

@mixin md1000-block() {
  @media screen and (max-width: $break_md1000) {
    @content;
  }
}

@mixin sm-block() {
  @media screen and (max-width: $break_sm) {
    @content;
  }
}

@mixin xs-block() {
  @media screen and (max-width: $break_xs) {
    @content;
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

// scrollbar line
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%),
  $border-color: transparent,
  $padding: 5px
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border: 1px solid $border-color;
    border-radius: $size;
    margin: $padding; // Добавляем отступы сверху и снизу
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

// gradient text

@mixin gradientText(
  $background-color:
    $GoldGradientDefault
) {
  & {
    background: $background-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@mixin gradientBorder(
  $border-radius: 8px,
  $background:
    $GoldGradientDefault,
   $border: 1px,
) {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: $border-radius;
    padding: $border;
    background: $background;
    z-index: -1;

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}

@mixin gradientBorderBottom(
  $background:
     $GoldGradientDefault
) {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;

    padding-bottom: 1px;
    background: $background;
    z-index: -1;

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}

///import css-variables from

@mixin font-face($family, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: local('#{$family}'),
    url('#{$path}.woff2') format('woff2'),
    url('#{$path}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}
