@import '@/app/styles/variables';
@import '@/app/styles/mixins';


.wrapper {
  width: 100%;
  height: 54px;
  border-radius: 22px;
  padding: 4px;
  display: flex;
  flex-flow: row;
  border: 1px solid #2A2A2A;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 550;
  line-height: 22.05px;
  letter-spacing: 0.002em;
  color: #A4A4A4;

  @include xs-block() {
    border-radius: 8px;
    height: 42px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.93px;
  }

  .element {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    cursor: pointer;

    @include xs-block() {
      height: 32px;
    }
  }

  .active {
    border-radius: 18px;
    background: $GoldGradientDefault;
    color: #060606;

    @include xs-block() {
      border-radius: 6px;
    }
  }
}