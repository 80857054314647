@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.basketHeader {
  background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%);
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.28px;
  letter-spacing: 0.002em;
  text-align: left;

  @include xs-block() {
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    padding: 0 9px 0 16px;

    svg {
      width: 16px;
      height: 16px;
    }
   }

  .content {
    display: flex;
    flex-flow: row;
    gap: 14px;
    height: 65px;
    align-items: center;

    @include xs-block() {
      height: 37px;
    }

    .basketButton {
      display: flex;
      flex-flow: row;
      gap: 8px;
      height: 100%;
      align-items: center;
      cursor: pointer;
      border-bottom: 2px solid transparent;

      .num {
        background: #111112;
        display: flex;
        height: 25px;
        align-items: center;
        padding: 7.5px 15px;
        z-index: 0;
        position: relative;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.98px;

        @include xs-block() {
          height: 22px;
          border-radius: 9px;
          padding: 8px 12px;
        }

        span {
          @include gradientText();
        }

        @include gradientBorder(9px);
      }
    }

    .active {
      border-bottom: 2px solid white;
    }
  }

  .settings,
  .close {
    cursor: pointer;
  }
}
