@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.infoWrapper {
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 47px;
  margin-top: -60px;

  @include sm-block() {
    margin-top: -30px;
  }

  .info {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.05px;
    text-align: justify;

    span {
      @include gradientText();
      font-weight: 600;
    }

    @include sm-block() {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02px;
    }
  }
}

.wrapper {
  border-radius: 16px;
  background: #060606;
  border: 1px solid #2A2A2A;
  padding: 60px;
  display: flex;
  flex-flow: column;
  gap: 40px;
  margin-top: -17px;
  margin-bottom: 130px;

  @include sm-block() {
    margin-top: -26px;
    gap: 0;
    margin-bottom: 50px;
    background: transparent;
    border: 0;
    padding: 0;
  }

  .row {
    display: flex;
    flex-flow: row;
    gap: 40px;
  }

  .col {
    display: flex;
    flex-flow: column;
    gap: 24px;
    width: 100%;
    margin-bottom: -29px;
  }

  .label {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.4px;
    text-align: left;
  }

  .button {
    width: 345px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    letter-spacing: 0.03em;
    margin-top: 29px;

    @include sm-block() {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 17.15px;
      margin-top: 36px;
    }
  }

  .error {
    span {
      position: absolute;
      bottom: -20px;
      font-size: 13px !important;
      text-transform: none !important;
      font-weight: 400 !important;
      color: $GreenGradientDefault;
      left: 0;

      @include sm-block() {
        bottom: -17px;
      }
    }
  }

  .inputContainer {
    @include sm-block() {
      border-radius: 8px;
    }
  }

  textarea:-internal-autofill-selected {
    box-shadow: 0 0 0 1000px $black inset;
    background-color: $black !important;
    -webkit-text-fill-color: #fff !important;
  }
}

.input {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid $gray;
  overflow: hidden;
  background: $black;
  min-height: 50px;
  color: #fff;
  padding: 12px 45px 12px 15px;
  outline: none;
  z-index: 1;
  pointer-events: auto;
  font-size: 16px;
  font-weight: 500;

  @include sm-block() {
    background: $BlackBackground;
  }

  @include sm-block() {
    min-height: 38px;
    padding: 7px 15px;
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  &.inputError {
    min-height: 50px;
    border: 1px solid $GreenGradientDefault !important;

    @include sm-block() {
      min-height: 38px;
    }
  }

  &::placeholder {
    color: #A4A4A4 !important;
    font-weight: 500;
    font-size: 16px !important;

    @include sm-block() {
      font-weight: 500;
      font-size: 14px !important;
    }
  }

  &::-ms-input-placeholder {
    color: $grayLight;
  }
}