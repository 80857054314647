@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.react-datepicker {
  font-family: $GilroyFont;
}

.react-datepicker__aria-live {
  display: none;
}

.calendar {
  background-color: $black !important;
  border-radius: 10px !important;
  border: 1px solid #443e3f !important;
  box-shadow: 0 3px 42px 0 #00000080;
}

.calendarDisplayNone {
  display: none;
}

.react-datepicker-wrapper {
  margin-bottom: 17px;
}

.calendarMobile {
  width: 288px !important;
  position: relative !important;
  margin-top: 0 !important;
  border: 1px solid #1a1a1a !important;
  padding: 10px 14px !important;
  background-color: #111112 !important;

  .react-datepicker__day-names {
    border-top: 1px solid #2a2a2a !important;
    border-bottom: 1px solid #2a2a2a !important;
    height: 36px;
    margin-bottom: 8px !important;
    background-color: #111112 !important;
  }

  .react-datepicker__week {
    height: 30px;
    background-color: #111112 !important;
    margin-bottom: 0 !important;

    :not(:last-child) {
      margin-top: 0;
    }
  }

  .react-datepicker__day {
    font-weight: 600 !important;
    width: 30px !important;
    height: 30px;
    background-color: #111112 !important;
  }

  .react-datepicker__day-name {
    font-size: 13px !important;
    font-weight: 600;
    line-height: 15.51px;
    letter-spacing: 0.1em;
  }

  .react-datepicker__month {
    width: 260px;
    padding: 0 1px 0 1px;
    gap: 8px;
    background-color: #111112 !important;
    display: flex;
    flex-flow: column;
  }

  .custom-header-datepicker,
  .react-datepicker__header,
  .react-datepicker__month-container {
    background-color: #111112 !important;
  }

  .react-datepicker__children-container {
    height: 32px;
    background-color: #111112 !important;

    .footer {
      height: 32px !important;
    }
  }

  .footer {
    height: 32px !important;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker-popper {
  z-index: 5;
  padding-top: 0 !important;
  right: 0 !important;
  width: 363px;

  @include sm-block() {
    max-width: 320px;
    width: 100%;
  }
}

.react-datepicker-popper > div {
  position: relative;
}

.react-datepicker {
  position: absolute;
  right: 0 !important;
  width: 366px;
  padding: 20px !important;

  @include xs-block() {
    width: 290px;
    padding: 10px 14px !important;
  }
}

.react-datepicker__header {
  padding-top: 0 !important;
}

.react-datepicker__header--custom {
  position: static;
  padding: 0 !important;
}

.react-datepicker__header--custom span,
.react-datepicker__header--custom option {
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
}

.react-datepicker__header--custom span {
  @include xs-block() {
    font-size: 14px !important;
  }
}

.react-datepicker__header--custom select {
  border: none;
}

.react-datepicker__header {
  border-bottom: inherit;
}

.custom-header-datepicker {
  // max-width: 257px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  margin: 0 auto 15px;

  @include xs-block() {
    height: 20px;
    margin-bottom: 10px;
  }
}

.custom-header-datepicker-year {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.7px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  color: #fff;
}

.custom-header__arrows {
  display: flex;
  gap: 24px;

  button {
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #fff;
    }
  }
}

.react-datepicker__current-month {
  margin-bottom: 5px !important;
  font-weight: 500;
  color: #fff !important;
}

.react-datepicker__navigation {
  width: 12px !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month-container,
.react-datepicker__header,
.react-datepicker__month {
  background-color: $black !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 100px;
  background-color: $gray !important;
}

.react-datepicker__day {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  width: 38px;
  height: 38px;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 38px;

  @include sm-block() {
    font-size: 14px !important;
    flex: 0 0 30px;
    width: 28px;
    height: 28px;
  }

  @include xs-block() {
    font-size: 14px !important;
  }
}

.react-datepicker__children-container {
  width: 100%;
  display: flex;
  margin: 0;
}

.react-datepicker__day--in-range {
  border-radius: 100% !important;
  background: #ebe9e9 !important;
}

.react-datepicker__day--in-selecting-range {
  background: #ccc !important;
  margin-right: 0 !important;
  position: relative;
  z-index: 1;
  border-radius: 100% !important;

  &:not(:last-child) {
    &::after {
      background-color: #ccc;
      position: absolute;
      width: 10px;
      height: 100%;
      z-index: -100;
      top: 0;
      left: 100%;
    }
  }

  &::after {
    content: none;
  }
}

.react-datepicker__day--range-start {
  background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%) !important;
  color: #fff !important;
  border-radius: 100% !important;

  &::after {
    width: 25px !important;
    left: 15px !important;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: $GoldGradientDefault !important;
  color: $black !important;
  border-radius: 100% !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 1px;
    background: linear-gradient(180deg, #fdf7d4 3.79%, #f1af7d 50.15%, #b87548 92.75%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  &::after {
    width: 25px !important;
    left: 15px !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background: $GoldGradientDefault !important;
  color: $black !important;
  border-radius: 100% !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 1px;
    background: $GoldGradientDefault;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  &::after {
    width: 25px !important;
    left: 15px !important;
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    background: $GoldGradientHover !important;
    color: $black !important;
    border-radius: 100% !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    @include xs-block() {
      color: $black !important;
      border-radius: 100% !important;
    }

    &::after {
      width: 25px !important;
    }
  }
}

.react-datepicker__day--in-selecting-range {
  background: $GoldGradientDefault !important;
  transition: all 0.3s ease 0s;
  position: relative;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 1px;
    background: $GoldGradientDefault;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  &.react-datepicker__day--outside-month {
    position: relative;
    color: #fff !important;

    &::before {
      content: none;

    }
  }
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-end {
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial;
  background: $GoldGradientDefault !important;
}

.react-datepicker__day--range-start {
  &.react-datepicker__day--range-end {
    &::after {
      content: none !important;
    }
  }
}

.react-datepicker__day--in-selecting-range-start,
.react-datepicker__day--in-selecting-range-end {
  background: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%) !important;
  color: #fff !important;

  &::after {
    content: none !important;
  }
}

.react-datepicker__day--outside-month {
  color: #565656 !important;
}

.react-datepicker__day--keyboard-selected {
  background: $GoldGradientDefault !important;
  color: $black !important;
  border-radius: 100% !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::after {
    // content: '' !important;
    width: 25px !important;
    left: 15px !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 1px;
    background: $GoldGradientDefault;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}

.react-datepicker__day:not(:last-child) {
  margin-right: 8px !important;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__week:not(:last-child) {
  margin-bottom: 8px;
  margin-top: 7px;

  @include xs-block() {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}

.react-datepicker__day-names {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-datepicker__day-name {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 12px;
  letter-spacing: 1.5px !important;
  padding: 4px 0 !important;
  width: 30px !important;
  text-transform: uppercase;
  margin: 0 !important;
  color: #fff !important;

  @include xs-block() {
    font-size: 10px !important;
  }
}

.react-datepicker__day-name:not(:last-child) {
  margin-right: 8px !important;
}

.react-datepicker-mobile-header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.7px;
  letter-spacing: 0.1em;
  background-image: $GoldGradientDefault !important;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  svg {
    rect {
      fill: $GoldGradientDefault !important;
    }
  }
}

.react-datepicker-mobile-header-bottom-line {
  border-bottom: 1px solid #2a2a2a;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.react-datepicker-mobile-footer {
  display: flex;
  height: 32px;
  align-items: end;
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #2a2a2a;
  justify-content: space-between;

  .button {
    color: #fff;
    border: none;
    background: inherit;
    outline: none;
    font-weight: 600;
  }
}

.react-datepicker-mobile-header-year {
  display: flex;
  align-items: center;
}

.modalSwiperContainer {
  display: flex;
  height: 168px;

  .swiper {
    min-width: 131px;
  }

  .swiper-slide.first-visible {
    font-size: 8px;
  }

  .swiper-slide.last-visible {
    font-size: 8px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #565656;
    font-size: 8px;
    text-transform: capitalize;
  }

  .swiper-slide-visible {
    font-size: 10px;
  }

  .swiper-slide-fully-visible {
    font-size: 10px;
  }

  .swiper-slide-prev {
    font-size: 12px;
  }

  .swiper-slide-next {
    font-size: 12px;
  }

  .swiper-slide-active {
    background-image: $GoldGradientDefault!important;
    border-top: 1px solid #2a2a2a;
    border-bottom: 1px solid #2a2a2a;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  .swiper-slide-shadow-top {
    background: transparent;
  }
}
